import React, { useState } from "react";
import logo from "../assets/logo.png";
// import Popup from "./Popup";
// import Popup from "./Popup.tsx"

const Footer = () => {
  const [showPopup, setShowPopup] = useState(false);

  const handlePrivacyClick = (e) => {
    e.preventDefault();
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  const handleSmoothScroll = (event, targetId) => {
		event.preventDefault()
		const targetElement = document.getElementById(targetId)
		if (targetElement) {
			targetElement.scrollIntoView({ behavior: "smooth" })
		}
	}

  return (
    <>
      <footer className='bg-teal-500 py-4'>
        <div className='max-w-6xl mx-auto flex flex-col md:flex-row items-center justify-between px-4 sm:px-6'>
          <div className='flex items-center mb-4 md:mb-0'>
            <img className='w-8 h-8 mr-2 rounded-full' src={logo} alt='Logo' />
            <p className='text-white text-sm text-center md:text-left'>
              &copy; 2024 Baby Formula Calculator App. All Rights Reserved.
            </p>
          </div>
          <div className='flex flex-wrap justify-center md:justify-end space-x-4 text-white text-sm'>
            <a href='#home' onClick={(e) => handleSmoothScroll(e, 'home')}>
              Home
            </a>
            <span className='hidden md:inline'>|</span>
            <a href='#aboutus' onClick={(e) => handleSmoothScroll(e, 'aboutus')}>
              About Us
            </a>
            <span className='hidden md:inline'>|</span>
            <a href='#features' onClick={(e) => handleSmoothScroll(e, 'features')}>
              Features
            </a>
            <span className='hidden md:inline'>|</span>
            <a href='#testimonials' onClick={(e) => handleSmoothScroll(e, 'testimonials')}>
              Testimonials
            </a>
            <span className='hidden md:inline'>|</span>
            <a href='#privacy'>
              Privacy Policy
            </a>
          </div>
        </div>
      </footer>

    </>
  );
};

export default Footer;
